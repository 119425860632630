import React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
    return (
        <div>
            <h1>404 Not Found</h1>
            <h2>Ooops, that page doesn't exists</h2>
            <p><Link to="/">Home</Link></p>
        </div>
    )
};


export default NotFoundPage;

